import { Suspense, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from "react-loader-spinner";
import ViewUserInfo from "./components/Header/ViewUserInfo";
import ChangePass from "./components/Header/ChangePass";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function Main(props) {
    const LoadingIndicator = (props) => {
        const { promiseInProgress } = usePromiseTracker();
        return (
            promiseInProgress && (
                <div
                    style={{
                        width: window.$(".content-wrapper .content").width(),
                        height: window.$(".content-wrapper .content").height(),
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(187,190,180,0.3)",
                        zIndex: 1000,
                        position: "absolute",
                    }}>
                    <ThreeDots color="blue" height="60" width="60" />
                </div>
            )
        );
    };
    ///////get data view info personal
    const users = localStorage.getItem("user-info") !== "" ? JSON.parse(localStorage.getItem("user-info")) : 0;
    const full_fields = {
        path_avatar: "",
        full_name: "",
        birthday: "",
        gender: 1,
        organize_name: "",
        organize_name_father: "",
        resident: "",
        email: "",
        phone: "",
    };
    const [fields, setField] = useState(full_fields);
    async function fetchViewPersonal() {
        const result = await fetch(global.config.url.api + "staff/getViewPersonal/" + users.id, {
            headers: {
                token: users.token,
                "user-id": users.id,
            },
        });
        const results = await result.json();
        if (results.data.length !== 0) {
            setField(results.data);
            if (results.data.path_avatar === null) {
                if (results.data.gender === 1) {
                    setField((prevState) => ({
                        ...prevState,
                        path_avatar: global.config.url.client + "/dist/img/avatar_boy.jpg",
                    }));
                } else {
                    setField((prevState) => ({
                        ...prevState,
                        path_avatar: global.config.url.client + "/dist/img/avatar_girl.jpg",
                    }));
                }
            } else {
                setField((prevState) => ({
                    ...prevState,
                    path_avatar: global.config.url.base + results.data.path_avatar,
                }));
            }
        }
    }
    ///out context begin
    const [organizeList, setOrganizeList] = useState([]);
    const [cateList, setCateList] = useState(global.config.cates);
    async function fetchOrganizeList() {
        const result = await fetch(global.config.url.api + "user/getOrganizeList", {
            ///is_area = 1
            headers: {
                token: users.token,
                "user-id": users.id,
            },
        });
        const results = await result.json();
        setOrganizeList(results.data);
    }
    ///////////end get don vi/////////
    useEffect(() => {
        fetchViewPersonal();
        fetchOrganizeList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {/* <Preloader></Preloader> */}

            <Header avatar={fields.path_avatar}></Header>
            <Sidebar></Sidebar>
            <div className="content-wrapper">
                <section className="content">
                    <LoadingIndicator />
                    <Suspense fallback={""}>
                        <Outlet context={[organizeList, cateList, setCateList]} />
                        {/*context={[organizeList,fields]*/}
                    </Suspense>
                </section>
                <ChangePass toast={toast} />
                <ViewUserInfo fields={fields} />
            </div>
            <Footer></Footer>
            <ToastContainer />
        </>
    );
}

export default Main;
