import { useLayoutEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
function SystemManage(props) {
    const navigate = useNavigate();
    const users = JSON.parse(localStorage.getItem("user-info"));
    const [menuList, setMenuList] = useState([]);
    async function getMenu() {
        const promise = new Promise((resolve, reject) => {
            resolve(
                fetch(global.config.url.api + "module_permission/getMenuSideBar/0", {
                    method: "GET",
                    headers: {
                        token: users.token,
                        "user-id": users.id,
                    },
                }).then((response) => response.json()),
            );
        }).then((results) => {
            if (results.code === 401) {
                navigate("/logout");
            }
            setMenuList(results.data);
        });
        return promise;
    }
    useLayoutEffect(() => {
        trackPromise(getMenu());
        ////auto set menu active
        window.$('[data-widget = "treeview"]').Treeview("init");
        window.$(document).on("click", '[data-widget="treeview"] .nav-link', function (e) {
            e.stopImmediatePropagation();
        });
        ////end set menu active
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function Menu(items, level = 0) {
        if (level === 0) {
            return items.map((item) => (
                <li key={item.id} className="nav-item">
                    <Link to={item.name_module} className={item.id === 1 ? "nav-link" : "nav-link"}>
                        <i className={item.path_icon}></i>
                        <p>
                            {item.name}
                            <i className="right fas fa-angle-left" />
                        </p>
                    </Link>
                    {item.children && Menu(item.children, 1)}
                </li>
            ));
        }
        if (level > 0)
            return (
                <ul className="nav nav-treeview">
                    {items.map((item) => (
                        <li key={item.id} className="nav-item">
                            <Link to={item.name_module} className="nav-link">
                                <i className={item.path_icon}></i>
                                <p>
                                    {item.name}
                                    {item.end === 0 && <i className="right fas fa-angle-left" />}
                                </p>
                            </Link>
                            {item.children && Menu(item.children, item.level)}
                        </li>
                    ))}
                </ul>
            );
    }
    return (
        <ul className="nav nav-pills nav-sidebar flex-column nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">
            <li className="nav-item">
                <Link className="nav-link" to={"./"}>
                    <i className="fas fa-home nav-icon"></i>
                    <p>Dashboard</p>
                </Link>
            </li>
            {Menu(menuList)}
        </ul>
    );
}

export default SystemManage;
