import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '../assets/css/App.css';
import { publicRouters, privateRouters } from './routers';
import { LayoutAdmin } from './layouts';
import Protected from './modules/Login/Protected';
function App() {
  return (
    <div className='wrapper'>
      <BrowserRouter basename={''}>
        <Routes>
          {
            publicRouters.map((route, index) => {
              const Page = route.component;
              let Layout = route.layout;
              return <Route exact={true} key={index} path={route.path} element={<Layout><Page /></Layout>} />
            })
          }
          <Route exact path="/" element={<Protected Cmp={LayoutAdmin} />}>
            {
              privateRouters.map((route, index) => {
                const Page = route.component;
                return <Route exact={true} key={index} path={route.path} element={<Page />} />
              })
            }
            <Route exact={true} path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div >
  );
}
function PageNotFound() {
  return (
    <div style={{ textAlign: 'center', paddingTop: '50px', color: 'red' }}>
      <h2>404 - Đường dẫn không tồn tại</h2>
    </div>
  );
}
export default App;
