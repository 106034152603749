import React from "react";
import { createRoot } from "react-dom/client"; ///React v18.2
// import ReactDOM from 'react-dom';///React v17
import "./config";
import "./assets/css/index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";

///For React V18
const root = createRoot(document.getElementById("root"));
root.render(<App />);

////for React V17
// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );
reportWebVitals();
