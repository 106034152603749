import { memo } from 'react';
function Footer(props) {
    return (
        <div>
            <footer className="main-footer text-sm">
                <strong>Bản quyền © 2022 <a href="https://sotttt.angiang.gov.vn">TT-CNTT&TT </a></strong>
                Đã đăng ký bản quyền.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 1.0
                </div>
            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
        </div>
    );
}

export default memo(Footer);