import React, { useLayoutEffect, useState, memo } from 'react';
import { Navigate } from 'react-router-dom';
function Protected(props) {
    let Cmp = props.Cmp;
    const [isLoggedIn, setLogin] = useState(true);
    useLayoutEffect(() => {
        if (localStorage.getItem('user-info') === null) {
            setLogin(false);
        }
    }, [])
    return isLoggedIn ? <div> <Cmp /> </div> : <Navigate to='/login' />
}
export default memo(Protected);