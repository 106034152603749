import { useState, memo } from 'react';

function ChangePass(props) {
    const users = JSON.parse(localStorage.getItem('user-info'));
    ///////////all field///////////
    const full_fields = {
        password_old: '', password_new: '', re_password_new: ''
    }
    const [fields, setField] = useState(full_fields);
    /////get value of field when onchange input////
    function handleChangeInput(e, id) {
        const value = e.target.value;
        setField(prevState => ({
            ...prevState,
            [id]: value
        }));
    }
    function setValueRepose(data = Object) {
        if (Object.keys(data).length === 0) {//view form add
            resetForm();
        }
        else {
            Object.keys(fields).map(key => {
                setField(prevState => ({
                    ...prevState,
                    [key]: (data[key] !== null) ? data[key] : ''
                }));
            });
        }
    }
    ////////set value add item in table///////
    function getFormData() {
        const formData = new FormData();
        Object.keys(fields).map(key => {
            formData.append([key], encodeScript(fields[key]))
        });
        return formData;
    }
    function encodeScript(str) {
        var encoded = "";
        str = btoa(str);
        str = btoa(str);
        for (var i = 0; i < str.length; i++) {
            var a = str.charCodeAt(i);
            var b = a ^ 10;
            encoded = encoded + String.fromCharCode(b);
        }
        encoded = btoa(encoded);
        return encoded;
    }
    //////update data in table//////////
    async function putChange() {
        let result = await fetch(global.config.url.api + 'user/changePass?_method=PUT', {
            method: 'POST',
            headers: {
                'token': users.token,
                'user-id': users.id
            },
            body: getFormData()
        });
        const kq = await result.json();
        if (kq.code === 200) {
            resetForm();
            window.$('#ChangePassModal').modal('hide');
            props.toast.success(kq.msg);
        } if (kq.code === 201 || kq.code === 301) {
            setValueRepose(kq.data);
            resetForm(kq.errors);
            props.toast.error(kq.msg);
        }
    }
    //////////reset form/////////
    const resetForm = (dataError = {}) => {
        Object.keys(full_fields).map(key => {
            window.$('#' + key).removeClass('is-invalid');
            window.$('#' + key).attr('data-original-title', window.$('#' + key).attr('placeholder'));
        });
        if (Object.keys(dataError).length === 0) {
            setField(full_fields);
        }
        if (Object.keys(dataError).length > 0) {
            Object.keys(dataError).map(key => {
                window.$('#' + key).addClass('is-invalid');
                window.$('#' + key).attr('data-original-title', dataError[key][0]);
            });
        }
    }
    return (
        <div className="modal fade" id="ChangePassModal" tabIndex={-1} role="dialog" aria-labelledby="ChangePassModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-primary" id="ChangePassModalLabel"><i className="fas fa-exclamation-circle"></i> Thay đổi mật khẩu</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" onClick={() => resetForm()}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input type="password" id="password_old" className="fadeIn one form-control mb-4" onChange={(e) => handleChangeInput(e, 'password_old')} value={fields.password_old ?? ''} data-original-title="" data-tooltip="tooltip" placeholder="Nhập mật khẩu cũ" />
                        <input type="password" id="password_new" className="fadeIn two form-control mb-4" onChange={(e) => handleChangeInput(e, 'password_new')} value={fields.password_new ?? ''} data-original-title="" data-tooltip="tooltip" placeholder="Nhập mật khẩu mới" />
                        <input type="password" id="re_password_new" className="fadeIn third form-control mb-4" onChange={(e) => handleChangeInput(e, 're_password_new')} value={fields.re_password_new ?? ''} data-original-title="" data-tooltip="tooltip" placeholder="Nhập lại mật khẩu mới" />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => putChange()}>Lưu thay đổi</button>
                        <button type="button" className="btn btn-secondary" onClick={() => resetForm()} data-dismiss="modal">Đóng lại</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(ChangePass);