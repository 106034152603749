export function encodeScript(str) {
    var encoded = "";
    str = btoa(str);
    str = btoa(str);
    for (var i = 0; i < str.length; i++) {
        var a = str.charCodeAt(i);
        var b = a ^ 10; // bitwise XOR with any number, e.g. 123
        encoded = encoded + String.fromCharCode(b);
    }
    encoded = btoa(encoded);
    return encoded;
}
export function encryptJS(text, passKey) {
    var CryptoJS = require("crypto-js");
    var encrypted = CryptoJS.AES.encrypt(text, passKey).toString();
    return encrypted;
}

export function decryptJS(text, passKey) {
    var CryptoJS = require("crypto-js");
    var decrypted = CryptoJS.AES.decrypt(text, passKey).toString(CryptoJS.enc.Utf8);
    return decrypted;
}