import { useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
///////////////////////////////////
function Logout(props) {
    const navigate = useNavigate();
    const users = JSON.parse(localStorage.getItem("user-info"));
    useEffect(() => {
        clearCacheUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    async function clearCacheUser() {
        const result = await fetch(global.config.url.api + "login/clearCache", {
            headers: {
                token: users.token,
                "user-id": users.id,
            },
        });
        const results = await result.json();
        if (results.code !== "") {
            if (localStorage.getItem("auth_sso_ag") !== null) {
                logout_sso();
            } else {
                navigate("/login");
            }
            window.localStorage.clear(); ///xóa toàn bộ
        }
    }
    const logout_sso = async () => {
        const auth_sso_ag = JSON.parse(localStorage.getItem("auth_sso_ag"));
        const token_sso_ag = JSON.parse(localStorage.getItem("token_sso_ag"));
        const url = "https://sso.angiang.gov.vn/oidc/logout";
        const data = {
            //client_id: global.config.sso_ag.client_key,
            id_token_hint: token_sso_ag.id_token,
            session_state: auth_sso_ag.session_state,
            post_logout_redirect_uri: global.config.url.client + "login",
        };
        window.location = "https://sso.angiang.gov.vn/oidc/logout?" + window.objectToQueryString(data);
    };
}
export default memo(Logout);
