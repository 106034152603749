// @flow
import { memo } from 'react';

function ViewUserInfo(props) {
    return (
        <div className="modal fade" id="ViewUserInfo" tabIndex={-1} role="dialog" aria-labelledby="ViewUserInfoModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title font-weight-bold" id="ChangePassModalLabel"><i className="fas fa-regular fa-address-card mr-2" style={{ color: '#00CC33' }}></i> Thông tin cá nhân</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <td rowSpan={3} width={150} ><img src={props.fields.path_avatar} style={{ height: "90%", width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} alt='Ảnh đại diện' /></td>
                                    <td width={150} className='text-center font-weight-bold'>Họ & Tên</td>
                                    <td>{props.fields.full_name}</td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>Ngày sinh</td>
                                    <td>{window.formatDate(props.fields.birthday)}</td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>Giới tính</td>
                                    <td>{props.fields.gender === 1 ? 'Nam' : 'Nữ'}</td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>Đơn vị</td>
                                    <td colSpan={2}>{props.fields.organize_name} - {props.fields.organize_name_father}</td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>Địa chỉ</td>
                                    <td colSpan={2}>{props.fields.resident}</td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>Email</td>
                                    <td colSpan={2}>{props.fields.email}</td>
                                </tr>
                                <tr>
                                    <td className='text-center font-weight-bold'>Điện thoại</td>
                                    <td colSpan={2}>{props.fields.phone}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Đóng lại</button>
                    </div>
                </div>
            </div>
        </div >
    );
};
export default memo(ViewUserInfo);