import { memo } from "react";

function LeftNavbar(props) {
    return (
        <ul className="navbar-nav">
            <li className="nav-item">
                <a className="nav-link" data-widget="pushmenu" href="/#" role="button">
                    <i className="fas fa-bars" />
                </a>
            </li>
            <li style={{ fontSize: "18px", padding: "8px", fontWeight: "bolder", whiteSpace: "nowrap" }}>{props.nameOrganizeManager ?? "Tỉnh An Giang"}</li>
        </ul>
    );
}

export default memo(LeftNavbar);
