import { memo } from 'react';
import { Link } from 'react-router-dom';
function UserLogin(props) {
    return (
        <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
                <i className="fas fa-solid fa-user-tie" style={{ color: '#007bff' }}></i>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <span className="dropdown-item dropdown-header">
                    <img src={props.avatar} className="img-circle elevation-2 mr-2" style={{ width: '25px', flexWrap: 'none' }} alt="" />
                    {props.users ? props.users.username : ''}
                </span>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item" data-toggle="modal" data-target="#ViewUserInfo" data-backdrop="static" data-keyboard="false">
                    <i className="fas fa-regular fa-address-card mr-2" style={{ color: '#00CC33' }}></i> Thông tin cá nhân
                </a>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item" data-toggle="modal" data-target="#ChangePassModal" data-backdrop="static" data-keyboard="false">
                    <i className="fas fa-solid fa-key mr-2" style={{ color: '#DAA520' }}></i> Đổi mật khẩu
                </a>
                <div className="dropdown-divider" />
                <Link to="/logout" className="dropdown-item">
                    <i className="fas fa-solid fa-power-off mr-2" style={{ color: 'red' }}></i> Đăng xuất
                </Link>
                <div className="dropdown-divider" />
                <a href="#" className="dropdown-item dropdown-footer">Xem tất cả về bạn</a>
            </div>
        </li>
    );
}

export default memo(UserLogin);