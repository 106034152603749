import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
function Register() {
    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    async function signUp() {
        let item = { name, password, email };
        ///console.warn(item);
        let result = await fetch("http://localhost:8000/api/register", {
            method: 'POST',
            body: JSON.stringify(item),
            headers: {
                "Content-Type": 'application/json',
                "Accept": 'application/json'
            }
        })
        result = await result.json();
        ///console.warn('result',result);
        localStorage.setItem("user-info", JSON.stringify(result.data));
        navigate('/add');
    }
    return (
        <>
            <div className="col-sm-6 offset-sm-3">
                <h1>Register Page</h1>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Nhập tên" />
                <br />
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Nhập mật khẩu" />
                <br />
                <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Nhập email" />
                <br />
                <button onClick={signUp} className="btn btn-primary">Đăng ký</button>
            </div>
        </>
    );
}
export default memo(Register);