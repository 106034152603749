import React, { useState, useEffect, memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { encodeScript } from "../EncryptData";
import "./css/default.css";
import image_logo from "./img/logo_ag.png";
import { wait } from "@testing-library/user-event/dist/utils";
import { decode as base64_decode, encode as base64_encode } from "base-64";

function Login() {
    ///////////////////
    const [flag] = useState(encodeScript("webApp"));
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [timeCurrent, setTimeCurrent] = useState("");
    const [sso_user_ag, setSsoUser] = useState("");

    const navigate = useNavigate();
    useEffect(() => {
        if (localStorage.getItem("user-info")) {
            navigate("/");
        }
        getTimeCurrentServer();
        login_sso();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    ////////////////open form login with sso ag///////
    const [values_sso_ag, setValues] = useState({
        response_type: "code", ////token, code
        client_id: global.config.sso_ag.client_key,
        nonce: global.config.sso_ag.client_secret,
        redirect_uri: global.config.url.client + "login", ////https://dev-sso.ictangiang.vn/login
        scope: "openid",
    });
    const login_sso_form_ag = () => {
        window.location = "https://sso.angiang.gov.vn/oauth2/authorize?" + window.objectToQueryString(values_sso_ag);
    };
    //////////////////////open form login with sso vneid////////////////////////
    const values_sso_vneid = {
        response_type: "code", ////token, code
        client_id: global.config.sso_vneid.client_key,
        nonce: global.config.sso_vneid.client_secret,
        redirect_uri: global.config.url.client + "login", ////https://dev-sso.ictangiang.vn/login
        scope: "openid",
    };
    const login_sso_form_vneid = () => {
        window.location = "https://ssovneid.teca.vn?" + window.objectToQueryString(values_sso_vneid);
    };
    ///////////end open form////////////////////////////////////
    async function login_sso() {
        const url = window.location.href;
        if (url.includes("?code=") === true) {
            const urls = url.split("?");
            const urlArray = urls[1].split("&");
            const code = urlArray[0].split("=");
            const session_state = urlArray[1].split("=");
            const auth_sso_ag = { code: code[1], session_state: session_state[1] };

            localStorage.setItem("auth_sso_ag", JSON.stringify(auth_sso_ag));
            /////lay token//////
            getToken_LoginSso(code[1]);
        }
    }
    const getToken_LoginSso = async (code) => {
        const url = "https://sso.angiang.gov.vn/oauth2/token";
        const data = new URLSearchParams({
            code: code,
            grant_type: "authorization_code",
            client_id: global.config.sso_ag.client_key,
            //client_secret: global.config.sso_ag.client_secret,///ko can bo ra
            redirect_uri: global.config.url.client + "login",
        });
        try {
            const response = await fetch(url, {
                method: "post",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: data,
            });
            if (response.ok) {
                const responseData = await response.json();
                localStorage.setItem("token_sso_ag", JSON.stringify(responseData));
                fetch("https://sso.angiang.gov.vn/oauth2/userinfo", {
                    method: "GET",
                    headers: {
                        Authorization: "Bearer " + responseData.access_token,
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        //console.warn(data.sub);
                        ///login with app
                        const currTime = new Date().getTime();
                        const d = encodeScript(data.sub + "<+>" + global.config.sso_ag.client_key + "<+>" + currTime + "<+>" + global.config.sso_ag.client_secret);
                        let item = { d };
                        fetch(global.config.url.api + "login", {
                            method: "POST",
                            body: JSON.stringify(item),
                            headers: {
                                "Content-Type": "application/json",
                                Accept: "application/json",
                            },
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                if (data.code === 200) {
                                    localStorage.setItem("user-info", JSON.stringify(data.data));
                                    navigate("/");
                                }
                                if (data.code === 201) {
                                    toast.error(data.msg);
                                }
                            })
                            .catch((error) => console.log(error));
                    })
                    .catch((error) => console.log(error));
            } else {
                console.error("Error fetching data:", response.status);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };
    //
    ///////////end value sso////////////
    async function getTimeCurrentServer() {
        let flags = { flag };
        let result = await fetch(global.config.url.api + "login/getTime", {
            method: "POST",
            body: JSON.stringify(flags),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        result = await result.json();
        setTimeCurrent(result.time);
    }
    async function login(event) {
        //Prevent page reload
        event.preventDefault();
        const d = encodeScript(email + "<+>" + password + "<+>" + timeCurrent);
        let item = { d };
        let result = await fetch(global.config.url.api + "login", {
            method: "POST",
            body: JSON.stringify(item),
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        result = await result.json();
        if (result.code === 200) {
            localStorage.setItem("user-info", JSON.stringify(result.data));
            navigate("/");
        }
        if (result.code === 201) {
            toast.error(result.msg);
        }
    }
    //////////log-out////////////
    return (
        <div className="wrappers fadeInDown">
            <div className="sidebar sidebar_login">
                <div className="logo">
                    <img src={image_logo} alt="" />
                </div>
                <div className="comp-name">
                    <div className="name">
                        Trung tâm Công nghệ
                        <br />
                        thông tin và truyền thông
                    </div>
                    <div className="local"> </div>
                </div>
                <div className="info">
                    <p>
                        <strong>Thông tin liên hệ:</strong>
                    </p>
                    <p>
                        <i className="fas fa-home"></i> - Số 82, Tôn Đức Thắng, phường Mỹ Bình, thành phố Long Xuyên, tỉnh An Giang
                    </p>
                    <p>
                        <i className="fas fa-phone"></i> - (0296) 3856.188
                    </p>
                    <p className="webs">
                        <a target="_blank" href="https://ictangiang.vn/">
                            <i className="fas fa-globe"></i> - ictangiang.vn
                        </a>
                    </p>
                    <p>
                        <i className="fas fa-envelope"></i> - cict@angiang.gov.vn{" "}
                    </p>
                </div>
            </div>
            <div id="formContent">
                <form onSubmit={(e) => login(e)}>
                    <div className="fadeIn first mb-2 text-primary font-weight-bold h5 mb-4">
                        {/* <i className="fas fa-sign-in-alt mr-3"></i>  */}
                        HỆ THỐNG THÔNG TIN
                        <br />
                        QUẢN LÝ ĐIỀU HÀNH CẤP PHƯỜNG XÃ
                    </div>
                    <input type="text" id="login" className="fadeIn second login_input mb-2" defaultValue={email} onChange={(e) => setEmail(e.target.value)} placeholder="Nhập tên đăng nhập" />
                    <br />
                    <input type="password" id="password" className="fadeIn third login_input mb-2" onChange={(e) => setPassword(e.target.value)} placeholder="Nhập mật khẩu" />
                    <br />
                    <button onClick={(e) => login(e)} className="btn fadeIn fourth login_submit btn-primary">
                        <i className="fas fa-sign-in-alt mr-3"></i>
                        <b>Đăng nhập</b>
                    </button>
                </form>
                {/* <span className="text-primary">Hoặc</span>
                <br />
                <button onClick={(e) => login_sso_form_ag(e)} className="btn fadeIn fourth login_sso btn-danger">
                    <i className="fas fa-sign-in-alt mr-3"></i>
                    Đăng nhập bằng Mail An Giang
                </button> */}
                {/* <button onClick={(e) => login_sso_form_vneid(e)} className="btn fadeIn fourth login_sso btn-info">
                    <i className="fas fa-sign-in-alt mr-3"></i>
                    Đăng nhập bằng tài khoản VNeID
                </button> */}
            </div>
            <ToastContainer />
        </div>
    );
}
export default memo(Login);
